<script>
  // TODO: maybe even simpler like https://www.w3schools.com/howto/howto_css_loader.asp
  /** @type {{style?: string}} */
  let { style = $bindable("") } = $props();
  if (style.indexOf("width:") == -1) {
    style = "width: 14px; height: 14px; " + style;
  }
</script>

<div class="ball-clip-rotate" {style}></div>

<style>
  .ball-clip-rotate {
    margin: 2px;

    border-radius: 100%;
    border: 2px solid #aaa;
    border: 2px solid #787878;

    background: transparent !important;
    border-bottom-color: transparent;

    animation-fill-mode: both;
    animation: rotate 1.25s 0s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(0.6);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
</style>

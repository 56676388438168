import { openBrowserWindow, parseQueryString } from "./util";
import { lsKeyDropboxEmail } from "./user";
import { captureEvent, telegramNotify } from "./http";
import { makeFileSystemDropbox } from "./fs-dropbox";
import { createDropboxConfig } from "./fs";

let dropbBoxClientID = "go8qowpuq9bmfqc"; // app key

/**
 * @typedef {Object} DopboxUserName
 * @property {string} abbreviated_name
 * @property {string} display_name
 * @property {string} familiar_name
 * @property {string} given_name
 * @property {string} surname
 */

// https://dropbox.github.io/dropbox-sdk-js/global.html#UsersFullAccount
/**
 * @typedef {Object} DropboxUsersFullAccount
 * @property {string} account_id
 * @property {string} email
 * @property {DopboxUserName} name
 * @property {string} locale
 * @property {boolean} email_verified
 * @property {string} country
 */

export async function getDropboxClient(accessToken, refreshToken) {
  // @ts-ignore
  let Dropbox = await import("https://esm.sh/dropbox@10.30.0");
  // console.log("Dropbox:", Dropbox);

  let opts = {
    clientId: dropbBoxClientID,
  };
  if (accessToken) {
    opts.accessToken = accessToken;
    opts.refreshToken = refreshToken;
  }
  return new Dropbox.Dropbox(opts);
}

// https://dropbox.github.io/dropbox-sdk-js/Dropbox.html#usersGetCurrentAccount__anchor
/**
 * @param {string} accessToken
 * @param {string} refreshToken
 * @returns {Promise<DropboxUsersFullAccount>}
 */
export async function dbxGetAccountInfo(accessToken, refreshToken) {
  console.log("dbxGetAccountInfo, tokens:", accessToken, refreshToken);
  const dbx = await getDropboxClient(accessToken, refreshToken);
  const ui = await dbx.usersGetCurrentAccount();
  return ui.result;
}

const lsKeyDrobBoxCodeVerifier = "fm-dropbox-code-verifier";
const lsKeyDropboxToken = "fm-dropbox-token";

const l = window.location;
export let dropboxRedirectURL =
  l.protocol + "//" + l.host + "/auth/dropboxlogin";

export async function connectDropbox(onConnected) {
  let dbx = await getDropboxClient();
  let authURL = await dbx.auth.getAuthenticationUrl(
    dropboxRedirectURL,
    undefined,
    "code",
    "offline",
    undefined,
    undefined,
    true
  );
  let cv = dbx.auth.codeVerifier;
  console.log("connectDropbox: codeVerifier:", cv);
  localStorage.setItem(lsKeyDrobBoxCodeVerifier, cv);
  window.addEventListener("storage", (e) => {
    waitForDropboxToken(e, onConnected);
  });
  openBrowserWindow(authURL, "Dropbox Login", 840, 760);
}

// will be called when localStorage is modified from a different window
// here we wait for dropbox token to be set
// triggered by completeDropboxAuth
async function waitForDropboxToken(e, onConnected) {
  console.log("waitForDropboxToken", e);
  if (e.key !== lsKeyDropboxToken) {
    return;
  }
  window.removeEventListener("storage", waitForDropboxToken);
  const result = JSON.parse(e.newValue);
  const accessToken = result.access_token;
  const refreshToken = result.refresh_token;

  console.log("dropbox accessToken changed to:", accessToken);
  if (!accessToken) {
    // shouldn't happen
    return;
  }

  let c = createDropboxConfig();
  c.accessToken = accessToken;
  c.refreshToken = refreshToken;

  const ui = await dbxGetAccountInfo(accessToken, refreshToken);
  c.email = ui.email;
  c.name = ui.name.display_name;

  const msg = `New Dropbox connection for ${ui.email}`;
  telegramNotify(msg);
  captureEvent("dropbox-login", {
    email: ui.email,
  });
  localStorage.setItem(lsKeyDropboxEmail, ui.email);

  localStorage.removeItem(lsKeyDropboxToken);
  localStorage.removeItem(lsKeyDrobBoxCodeVerifier);

  const fs = await makeFileSystemDropbox(c);
  onConnected(fs);
}

// called from index.html
export async function completeDropboxAuth() {
  console.log("completeDropboxAuth: location.search:", window.location.search);
  let code = parseQueryString(window.location.search).code;
  const dbx = await getDropboxClient();
  let cv = localStorage.getItem(lsKeyDrobBoxCodeVerifier);
  console.log("codeVerifier:", cv);
  dbx.auth.setCodeVerifier(cv);
  let rsp = await dbx.auth.getAccessTokenFromCode(dropboxRedirectURL, code);
  console.log("completeDropboxAuth: onResponse: result:", rsp.result);
  let js = JSON.stringify(rsp.result);
  // this will trigger waitForDropboxToken in original window
  localStorage.setItem(lsKeyDropboxToken, js);
  window.close();
}

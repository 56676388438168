<script>
  import { run } from 'svelte/legacy';

  import { getContext } from "svelte";
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";
  import { join } from "./filepath";

  /** @type {{
    currentDir?: string,
    allowNested?: boolean,
    open?: boolean,
    fnNewDirectory?: (dirName: string) => void
  }} */
  let {
    currentDir = "",
    allowNested = false,
    open = $bindable(false),
    fnNewDirectory = $bindable(() => {})
  } = $props();

  let dirName = $state("");
  let fullNewPath = $state("");
  let createDisabled = $state(true);
  let showNoNestedError = $state(false);

  function calcShowNoNested(s) {
    if (!allowNested && s.includes("/")) {
      return true;
    }
    return false;
  }

  /**
   * @param {KeyboardEvent} ev
   */
  function handleKeyDown(ev) {
    console.log("DialogNewDirectory: handleKeyDown ev:", ev);
    let isEnter = ev.key == "Enter";
    if (isEnter && !createDisabled) {
      // dispatch("fmnewdir", fullNewPath);
      ev.preventDefault();
      ev.stopPropagation();
      fnNewDirectory(fullNewPath);
      return;
    }
  }

  function close() {
    open = false;
  }
  run(() => {
    fullNewPath = join(currentDir, dirName);
  });
  run(() => {
    showNoNestedError = calcShowNoNested(dirName);
  });
  run(() => {
    createDisabled = dirName == "" || showNoNestedError;
  });
</script>

<DialogBase bind:open title="Create New Directory">
  {#snippet main()}
    <div role="form" class="flex flex-col" >
      <input
        onkeydown={handleKeyDown}
        type="text"
        autocomplete="off"
        use:focus
        size="64"
        class="border-b mt-4 px-2 py-1 focus:outline-none focus:border-gray-400"
        bind:value={dirName}
      />
      {#if showNoNestedError}<div class="text-red-600 mt-4">
          sub-directories not allowed
        </div>
      {/if}
      <div class="text-sm text-gray-600 mt-4">
        Will create directory: <span class="bg-gray-50 font-mono"
          >{fullNewPath}</span
        >
      </div>
    </div>
  {/snippet}

  {#snippet bottom()}
    <div  class="flex justify-end">
      <button
        class="dlg-btn"
        onclick={close}>Cancel</button
      >
      <button
        disabled={createDisabled}
        class="ml-8 dlg-btn font-semibold"
        onclick={() => {
        fnNewDirectory(fullNewPath);
      }}>Create</button
      >
    </div>
  {/snippet}
</DialogBase>

<script>
  /** @typedef {import("./fs").FSConfig} FSConfig */
  /** @typedef {import("./bookmarks").Bookmark} Bookmark */
  /** @typedef {import("./fs-s3").S3Config} S3Config */
  /** @typedef {import("./fs-backblaze").BackBlazeConfig} BackBlazeConfig */

  import {
    createLocalConfig,
    createTestFilesConfig,
    fsConfigFullName,
    fsFullName,
    fsTypeBackBlaze,
    fsTypeDropbox,
    fsTypeFromFSConfig,
    fsTypeOneDrive,
    fsTypeS3,
    fsTypeTestFiles,
  } from "./fs";
  import { makeFileSystemLocal } from "./fs-local";
  import { makeFileSystemTest } from "./fs-testfiles";
  import { connectDropbox } from "./fs-dropbox-login";
  import { connectOneDrive } from "./fs-onedrive-login";

  import { len, isDev, throwIf } from "./util";
  import { bookmarkName } from "./bookmarks";
  import { makeFileSystemOneDrive } from "./fs-onedrive";

  import {
    mountedFileSystems,
    removeBookmark,
    savedFsConfigs,
  } from "./state.svelte";
  import { makeFileSystemS3 } from "./fs-s3";
  import { makeFileSystemBackBlaze } from "./fs-backblaze";

  import DialogConfigS3 from "./DialogConfigS3.svelte";
  import DialogConfigBackBlaze from "./DialogConfigBackBlaze.svelte";

  /** @type {{gotobookmark: (b) => void, mountfs: (fs) => void}} */
  let { gotobookmark, mountfs } = $props();

  let h = window.location.host;

  let showingDialogConfigBackBlaze = $state(false);
  let showingDialogConfigS3 = $state(false);

  // we only support one of those
  let connectedDropbox = $derived.by(() => {
    return mountedFileSystems.some(
      (fs) => fsTypeFromFSConfig(fs.config) == fsTypeDropbox
    );
  });
  let connectedOneDrive = $derived.by(() => {
    return mountedFileSystems.some(
      (fs) => fsTypeFromFSConfig(fs.config) == fsTypeOneDrive
    );
  });
  let showTestFiles = $derived.by(() => {
    if (!h.includes("localhost")) {
      return false;
    }
    return !mountedFileSystems.some(
      (fs) => fsTypeFromFSConfig(fs.config) == fsTypeTestFiles
    );
  });

  let bookmarksStorage = $state([]);
  let bookmarksBookmarks = $state([]);

  /**
   * @param {Bookmark[]} a
   */
  function updateConnectedFileSystems(a) {
    console.log("updateConnectedFileSystems");
    bookmarksStorage = [];
    bookmarksBookmarks = [];

    // TODO: for s3, sort by endpoint first then by bucket
    function cmpBookmarkByName(b1, b2) {
      let n1 = fsConfigFullName(b1);
      let n2 = fsConfigFullName(b2);
      if (n1 > n2) {
        return 1;
      } else if (n1 < n2) {
        return -1;
      }
      return 0;
    }
    bookmarksStorage.sort(cmpBookmarkByName);
  }

  async function pickLocalFolder() {
    let dirHandle;
    try {
      // @ts-ignore
      dirHandle = await window.showDirectoryPicker();
    } catch {
      console.log("pickLocalHolder: dismissed the folder pickup");
      return;
    }
    let c = createLocalConfig(dirHandle, dirHandle.name);
    const fs = makeFileSystemLocal(c);
    mountfs(fs);
  }

  function connectTestFiles() {
    let config = createTestFilesConfig();
    let fs = makeFileSystemTest(config);
    mountfs(fs);
  }

  async function onConnectOneDrive() {
    let config = await connectOneDrive();
    console.log("onConnectedOneDrive: config:", config);
    const fs = makeFileSystemOneDrive(config);
    console.log("onConnected one drive: fs:", fs);
    mountfs(fs);
  }

  function onConnectDropbox() {
    connectDropbox(onConnected);
    function onConnected(fs) {
      console.log("onConnected dropbox: fs:", fs);
      mountfs(fs);
    }
  }

  // @ts-ignore
  let hasFileSystemSupport = window.showDirectoryPicker;
  // per Sentry, Chrome Mobile 102 doesn't have storage.getDiretory function
  let hasStorage = !!(navigator.storage && navigator.storage.getDirectory);

  /**
   * @param {Bookmark} b
   */
  function selectBookmark(b) {
    console.log("selectBookmark", b);
    gotobookmark(b);
    return;
  }

  /**
   * @param {FSConfig} c
   */
  function mountFsConfig(c) {
    console.log("mountFsConfig", c);
    let fsType = fsTypeFromFSConfig(c);
    let fs;
    switch (fsType) {
      case fsTypeS3:
        let s3Config = /** @type {S3Config} */ (c);
        fs = makeFileSystemS3(s3Config);
        break;
      case fsTypeBackBlaze:
        let bbConfig = /** @type {BackBlazeConfig} */ (c);
        fs = makeFileSystemBackBlaze(bbConfig);
        break;
      default:
        throwIf(true, "unsupported fsType " + fsType);
    }
    mountfs(fs);
  }
</script>

<div
  class="max-h-full flex flex-col bg-white border-2 border-gray-300 overflow-y-auto focus-within:border-blue-600 focus-within:outline-none"
>
  <div class="py-1 px-2 font-semibold text-center">Connect File System</div>
  {#if !connectedDropbox}
    <button onclick={onConnectDropbox} class="btn">Dropbox</button>
    <!-- <a class="text-blue-600 underline ml-4 hidden" href="">help</a> -->
  {/if}

  {#if !connectedOneDrive}
    <button onclick={onConnectOneDrive} class="btn">One Drive</button>
    <!-- <a class="text-blue-600 underline ml-4 hidden" href="">help</a> -->
  {/if}

  <button onclick={() => (showingDialogConfigS3 = true)} class="btn">S3</button>

  {#if isDev()}
    <button onclick={() => (showingDialogConfigBackBlaze = true)} class="btn"
      >BackBlaze</button
    >
    <!-- <a class="text-blue-600 underline ml-4 hidden" href="">help</a> -->
  {/if}

  {#if hasFileSystemSupport}
    <button onclick={pickLocalFolder} class="btn">Local Folder</button>
  {/if}

  {#if showTestFiles}
    <button onclick={connectTestFiles} class="btn">Test Files</button>
  {/if}

  {#if len(mountedFileSystems) > 0}
    <div class="py-1 px-2 font-semibold text-center">Connected</div>
    {#each mountedFileSystems as fs}
      <button class="btn" onclick={() => mountfs(fs)}>
        {fsFullName(fs)}
      </button>
    {/each}
  {/if}

  {#if len(savedFsConfigs) > 0}
    <div class="py-1 px-2 font-semibold text-center">Saved Connections</div>
    {#each savedFsConfigs as c}
      <button class="btn" onclick={() => mountFsConfig(c)}>
        {fsConfigFullName(c)}
      </button>
    {/each}
  {/if}

  {#if len(bookmarksStorage)}
    <div class="self-center font-bold">Connected Storage Services</div>
    <div
      class="flex flex-col mt-2 max-w-[85%] max-h-[50vh] overflow-y-auto px-3"
    >
      {#each bookmarksStorage as b}
        <div class="flex flex-row justify-between items-baseline text-sm">
          <button
            onclick={() => selectBookmark(b)}
            class="self-left hover:bg-gray-200 cursor-pointer px-2 py-1 truncate"
            tabindex="0"
          >
            {bookmarkName(b)}
          </button>
          <button
            class="ml-12 hover:underline hover:underline-offset-2 cursor-pointer text-gray-400 text-sm"
            onclick={() => removeBookmark(b)}
          >
            remove
          </button>
        </div>
      {/each}
    </div>
  {/if}

  {#if len(bookmarksBookmarks)}
    <div class="self-center font-bold">Bookmarks</div>
    <div class="flex flex-col max-w-[85%] max-h-[50vh] overflow-y-auto px-3">
      {#each bookmarksBookmarks as b}
        <div class="flex flex-row justify-between items-baseline text-sm">
          <button
            onclick={() => selectBookmark(b)}
            class="self-left hover:bg-gray-200 cursor-pointer px-2 py-1 truncate"
            tabindex="0"
          >
            {bookmarkName(b)}
          </button>
          <button
            class="ml-12 hover:underline hover:underline-offset-2 cursor-pointer text-gray-400 text-sm"
            onclick={() => removeBookmark(b)}
          >
            remove
          </button>
        </div>
      {/each}
    </div>
  {/if}
</div>

{#if showingDialogConfigBackBlaze}
  <DialogConfigBackBlaze bind:open={showingDialogConfigBackBlaze} {mountfs} />
{/if}
{#if showingDialogConfigS3}
  <DialogConfigS3 bind:open={showingDialogConfigS3} {mountfs} />
{/if}

<style>
  .btn {
    @apply py-1 px-4 hover:bg-gray-200 text-left text-xs;
  }
</style>


    import { mount } from "svelte";
    import Main from './Main.svelte';
    import { completeDropboxAuth } from "./fs-dropbox-login";
    import { completeOneDriveAuth } from "./fs-onedrive-login";
    import './main.css';
    import { createUserIDAndIdentify } from "./user";
    import { installDev } from "./dev";

    installDev();

    const path = location.pathname;
    if (path == "/auth/dropboxlogin") {
      console.log("before completeDropboxAuth()");
      completeDropboxAuth();
    } else if (path == "/auth/onedrivelogin") {
      console.log("before completeOneDriveAuth()");
      completeOneDriveAuth();
    } else {
      createUserIDAndIdentify();
      const app = mount(Main, {
        target: document.getElementById('app'),
      });
    }
  
<script>
  import { getContext } from "svelte";
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";

  import { len, inflect, fmtSize } from "./util";

  /** @type {{
      files?: import("./fs").FileInfo[],
      open?: boolean,
      fnDeleteFiles: (paths: string[]) => void
    }} */
  let {
    files = [],
    open = $bindable(false),
    fnDeleteFiles
  } = $props();

  let limitedFiles = [];
  let paths = [];
  let nFiles = 0;
  let nDirs = $state(0);
  let dirInflected = $derived(nDirs == 1 ? "directory" : "directories");
  let totalSize = 0;

  let max = 10;

  let n = len(files);
  for (let i = 0; i < n; i++) {
    let fi = files[i];
    let path = fi.path;
    totalSize += fi.size;
    paths.push(path);
    if (i < max) {
      limitedFiles.push(fi);
    }
    if (path.endsWith("/")) {
      nDirs++;
    } else {
      nFiles++;
    }
  }

  let nNotShown = n - max;

  function msg() {
    let s = "Delete ";
    if (nDirs > 0) {
      s += ` <b>${nDirs}</b> ` + inflect("dir", nDirs);
      if (nFiles > 0) {
        s += ` and `;
      }
    }
    if (nFiles > 0) {
      s += `<b>${nFiles}</b> ` + inflect("file", nFiles);
      s += " of total size <b>";
      s += fmtSize(totalSize) + "</b>";
    }
    s += "?";
    return s;
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Delete">
  {#snippet main()}
    <div >
      <div>{@html msg()}</div>
      <div class="mt-4">Will delete:</div>

      <div class="table w-full">
        <div class="table-row-group">
          {#each limitedFiles as fi}
            <div class="table-row font-mono">
              <div class="table-cell pl-4 text-sm truncate max-w-[80vw]">
                {fi.path}
              </div>
              <div class="table-cell pl-4 text-sm text-right  ">
                {fmtSize(fi.size)}
              </div>
            </div>
          {/each}
          {#if nNotShown > 0}
            <div class="font-mono ml-4 text-sm truncate max-w-[80vw]">
              ... and {nNotShown} more
            </div>
          {/if}
          {#if nDirs > 0}
            <div class="font text-red-500 text-sm mt-2">
              Warning: deleting <span class="font-bold">{nDirs}</span>
              {dirInflected}. Could delete lots of files!
            </div>
          {/if}
        </div>
      </div>
    </div>
  {/snippet}

  {#snippet bottom()}
    <div  class="flex justify-end">
      <button
        use:focus
        class="dlg-btn"
        onclick={close}>Cancel</button
      >
      <button
        class="ml-8 dlg-btn-danger"
        onclick={() => {
        fnDeleteFiles(paths);
      }}>Delete</button
      >
    </div>
  {/snippet}
</DialogBase>

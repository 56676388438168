import {
  fsTypeDropbox,
  fsTypeLocal,
  fsTypeS3,
  fsTypeTestFiles,
  fsTypeOneDrive,
  fsTypeBackBlaze,
  FSDirState,
  FS,
} from "./fs";
import { makeFileSystemDropbox } from "./fs-dropbox";
import { makeFileSystemOneDrive } from "./fs-onedrive";
import { makeFileSystemLocal } from "./fs-local";
import { makeFileSystemS3 } from "./fs-s3";
import { makeFileSystemTest } from "./fs-testfiles";
import { cmpStringsNoCase } from "./sort";
import { throwIf } from "./util";
import { makeFileSystemBackBlaze } from "./fs-backblaze";

/** @typedef {import("./fs").FSConfig} FSConfig */

/** 
  @typedef {{
    fsID: string,  // same as FSConfigBase.fsID
    dir: string,
}} Bookmark 
*/

/**
 * @param {FSDirState} fsState
 * @returns {Bookmark}
 */
export function bookmarkFromFSState(fsState) {
  return {
    fsID: fsState.fs.config.fsID,
    dir: fsState.currDir,
  };
}

/**
 * @param {Bookmark} b
 * @returns {string}
 */
export function bookmarkName(b) {
  return b.fsID + ":" + b.dir;
}

/**
 * return true if bookmark represents the same location as filesystem
 * @param {Bookmark} [b]
 * @param {FS} [fs]
 */
export function bookmarkEqFS(b, fs) {
  return b.fsID == fs.config.fsID;
}

/**
 * @param {Bookmark} b1
 * @param {Bookmark} b2
 * @returns {boolean}
 */
function cmpByBookmarkName(b1, b2) {
  const s1 = bookmarkName(b1);
  const s2 = bookmarkName(b2);
  return cmpStringsNoCase(s1, s2);
}

export function sortBookmarks(a) {
  a.sort(cmpByBookmarkName);
}

/**
 * @param {Bookmark} b
 */
export function makeFSFromBookmark(b) {
  /** @type {FSConfig} */
  let config = null;
  for (let existingFS of fileSystems) {
    config = existingFS.config;
    if (b.fsID == config.fsID) {
      console.log("makeFSFromBookmark: found fs");
      return existingFS;
    }
  }

  /** @type {FS} */
  let res;
  switch (config.type) {
    case fsTypeTestFiles:
      res = makeFileSystemTest(config);
      break;

    case fsTypeLocal:
      res = makeFileSystemLocal(config);
      break;

    case fsTypeDropbox:
      res = makeFileSystemDropbox(config);
      break;

    case fsTypeOneDrive:
      res = makeFileSystemOneDrive(config);
      break;

    case fsTypeBackBlaze:
      res = makeFileSystemBackBlaze(config);
      break;

    case fsTypeS3:
      res = makeFileSystemS3(config);
      break;

    default:
      throwIf(true, "unsupported type " + config.type);
      break;
  }
  fileSystems.push(fs);
  console.log("makeFSFromBookmark: made new one");
  return res;
}

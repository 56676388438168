<script>
  /** @type {{size?: string, title?: any, [key: string]: any}} */
  let { size = "16", title, ...rest } = $props();

  // $: labelled = $$props["aria-label"] || $$props["aria-labelledby"] || title;
  // $: attributes = {
  //   "aria-hidden": labelled ? undefined : true,
  //   role: labelled ? "img" : undefined,
  //   focusable: Number($$props["tabindex"]) === 0 ? "true" : undefined,
  // };
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
  fill="currentColor"
  preserveAspectRatio="xMidYMid meet"
  width={size}
  height={size}
  {...rest}
>
  {#if title}<title>{title}</title>{/if}
  <path
    d="M16,6.52l2.76,5.58.46,1,1,.15,6.16.89L22,18.44l-.75.73.18,1,1.05,6.13-5.51-2.89L16,23l-.93.49L9.56,26.34l1-6.13.18-1L10,18.44,5.58,14.09l6.16-.89,1-.15.46-1L16,6.52M16,2l-4.55,9.22L1.28,12.69l7.36,7.18L6.9,30,16,25.22,25.1,30,23.36,19.87l7.36-7.17L20.55,11.22Z"
  ></path>
</svg>

<script>
  /** @typedef {import("./fs").FSEntry} FSEntry  */
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";

  import { fmtSize, inflect, len } from "./util";

  /** @type {{
    entries?: FSEntry[],
    fromDir?: string,
    toDir?: string,
    isMove?: boolean,
    doit?: any,
    open?: boolean
  }} */
  let {
    entries = [],
    fromDir = "",
    toDir = "",
    isMove = false,
    doit = null,
    open = $bindable(false)
  } = $props();

  /** @type {FSEntry[]} */
  let limitedEntries = [];
  let nFiles = 0;
  let nDirs = 0;
  let totalSize = 0;

  let max = 10;

  let n = len(entries);
  for (let e of entries) {
    if (len(limitedEntries) < max) {
      limitedEntries.push(e);
    }
    if (e.isDir) {
      nDirs++;
    } else {
      nFiles++;
    }
    totalSize += e.size;
  }

  let verb = isMove ? "move" : "copy";
  let title = isMove ? "Move" : "Copy";

  let nNotShown = n - max;

  function msg() {
    let s = "Copy ";
    if (isMove) {
      s = "Move ";
    }
    if (nDirs > 0) {
      s += ` <b>${nDirs}</b> ` + inflect("dir", nDirs);
      if (nFiles > 0) {
        s += ` and `;
      }
    }
    if (nFiles > 0) {
      s += `<b>${nFiles}</b> ` + inflect("file", nFiles);
      s += " of total size <b>";
      s += fmtSize(totalSize) + "</b>";
    }
    s += "?";
    return s;
  }

  function fmtName(e) {
    if (e.isDir) {
      return e.name + "/";
    }
    return e.name;
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open {title}>
  {#snippet main()}
    <div >
      <div>{@html msg()}</div>

      <div class="mt-2 table">
        <div class="table-row-group">
          <div class="table-row">
            <div class="table-cell">
              <div>From:</div>
            </div>
            <div class="table-cell max-w-[80vw] truncate pl-4">
              {fromDir}
            </div>
          </div>

          <div class="table-row">
            <div class="table-cell">
              <div>To:</div>
            </div>
            <div class="table-cell pl-4 max-w-[80vw] truncate">
              {toDir}
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">To {verb}:</div>

      <div class="table w-full">
        <div class="table-row-group">
          {#each limitedEntries as e}
            <div class="table-row font-mono">
              <div class="table-cell pl-4 text-sm max-w-[80vw] truncate">
                {fmtName(e)}
              </div>
              <div class="table-cell pl-4 text-sm text-right">
                {fmtSize(e.size)}
              </div>
            </div>
          {/each}
          {#if nNotShown > 0}
            <div class="font-mono ml-4 text-sm max-w-[80vw] truncate">
              ... and {nNotShown} more
            </div>
          {/if}
        </div>
      </div>
    </div>
  {/snippet}

  {#snippet bottom()}
    <div  class="flex justify-end">
      <button
        use:focus
        class="mr-8 dlg-btn"
        onclick={close}>Cancel</button
      >
      {#if isMove}
        <button
          class="dlg-btn font-semibold"
          onclick={doit}>Move Files</button
        >
      {:else}
        <button
          class="dlg-btn font-semibold"
          onclick={doit}>Copy Files</button
        >
      {/if}
    </div>
  {/snippet}
</DialogBase>

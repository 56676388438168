import { fnNoOp, len, throwIf } from "./util";

const menuSeparator = "---";

export class MenuItem {
  label = "";
  shortcut = "";
  /** @type {() => void} */
  handler;
  /** @type {MenuItem[]} */
  items = [];

  /**
    @param {string} l
    @param {() => void} h
    @param {MenuItem[]} items
  */
  constructor(l, h, items = []) {
    throwIf(!l, "needs label");
    throwIf(!h && l != menuSeparator && len(items) == 0, "needs handler");
    this.label = l;
    this.handler = h;
    this.items = items;
  }
  get isShowing() {
    return len(this.items) > 0;
  }
  isSeparator() {
    return this.label == menuSeparator;
  }
  isSubMenu() {
    return len(this.items) > 0;
  }
}

export const menuItemSeparator = new MenuItem(menuSeparator, fnNoOp);

/**
 @param {string} l
 @param {MenuItem[]} items
 @returns {MenuItem}
 */
export function makeSubMenu(l, items) {
  return new MenuItem(l, null, items);
}

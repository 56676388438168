<script>
  import { getContext } from "svelte";
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";
  import { len, inflect, fmtSize } from "./util";

  
  /** @type {{files?: import("./fs").FileInfo[], open?: boolean}} */
  let { files = [], open = $bindable(false) } = $props();

  let fnUploadFiles = getContext("fnUploadFiles");

  let limitedFiles = [];
  let nFiles = 0;
  let totalSize = 0;

  let max = 10;

  let n = len(files);
  for (let i = 0; i < n; i++) {
    let fi = files[i];
    if (i < max) {
      limitedFiles.push(fi);
    }
    nFiles++;
    totalSize += fi.size;
  }

  let nNotShown = n - max;

  function msg() {
    let s = "Upload ";
    s +=
      `<b>${nFiles}</b> ` +
      inflect("file", nFiles) +
      " of total size <b>" +
      fmtSize(totalSize) +
      "</b> ?";
    return s;
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Upload Files">
  {#snippet main()}
    <div  class="flex flex-col">
      <div>{@html msg()}</div>
      <div class="mt-4">Files to upload:</div>

      <div class="table">
        <div class="table-row-group">
          {#each limitedFiles as fi}
            <div class="table-row font-mono">
              <!-- TODO: better way to truncate table -->
              <div class="table-cell pl-4 text-sm max-w-[60vw] truncate">
                {fi.name}
              </div>
              <div class="table-cell pl-4 text-sm text-right  ">
                {fmtSize(fi.size)}
              </div>
            </div>
          {/each}
          {#if nNotShown > 0}
            <div class="font-mono ml-4 text-sm truncate maxw">
              ... and {nNotShown} more
            </div>
          {/if}
        </div>
      </div>
    </div>
  {/snippet}

  {#snippet bottom()}
    <div  class="flex justify-end">
      <button
        use:focus
        class="dlg-btn"
        onclick={close}>Cancel</button
      >

      <button
        class="ml-8 dlg-btn font-semibold"
        onclick={fnUploadFiles}>Upload</button
      >
    </div>
  {/snippet}
</DialogBase>

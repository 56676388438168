<script>
  import { getContext } from "svelte";
  import DialogBase from "./DialogBase.svelte";
  import { focus } from "./actions/focus";
  import { len, inflect } from "./util";

  /** @type {{open?: boolean, paths?: any}} */
  let { open = $bindable(false), paths = [] } = $props();

  let fnDeleteFiles = getContext("fnDownloadFiles");

  let limitedPaths = [];
  let nFiles = 0;
  let nDirs = $state(0);
  let dirInflected = $derived(nDirs == 1 ? "directory" : "directories");

  let max = 8;

  let n = len(paths);
  for (let i = 0; i < n; i++) {
    let path = paths[i];
    if (i < max) {
      limitedPaths.push(path);
    }
    if (path.endsWith("/")) {
      nDirs++;
    } else {
      nFiles++;
    }
  }

  let nNotShown = n - max;
  if (nNotShown > 0) {
    let s = `... and ${nNotShown} more`;
    limitedPaths.push(s);
  }

  function msg() {
    let s = "Download ";
    if (nFiles > 0) {
      s += `<b>${nFiles}</b> ` + inflect("file", nFiles);
      if (nDirs > 0) {
        s += " and";
      }
    }
    if (nDirs > 0) {
      s += ` <b>${nDirs}</b> ` + inflect("dir", nDirs);
    }
    s += "?";
    return s;
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Download">
  {#snippet main()}
    <div  class="flex flex-col">
      <div>{@html msg()}</div>
      <div class="mt-4">Will download:</div>
      {#each limitedPaths as path}
        <div class="font-mono ml-4 text-sm truncate maxw">{path}</div>
      {/each}
      {#if nDirs > 0}
        <div class="font text-red-500 text-sm mt-2">
          Warning: downloading <span class="font-bold">{nDirs}</span>
          {dirInflected}. Could download lots of files!
        </div>
      {/if}
    </div>
  {/snippet}

  {#snippet bottom()}
    <div  class="flex justify-end">
      <button
        use:focus
        class="dlg-btn"
        onclick={close}>Cancel</button
      >
      <button
        class="ml-8 dlg-btn font-semibold "
        onclick={fnDeleteFiles(paths)}>Download</button
      >
    </div>
  {/snippet}
</DialogBase>

export let testFiles = [
  {
    name: "META-INF/container.xml",
    size: 180,
  },
  { name: "mimetype", size: 20, sizeUncompressed: 20 },
  { name: "OEBPS/content.opf", size: 1218, sizeUncompressed: 7185 },
  {
    name: "OEBPS/Images/cover.jpeg",
    size: 108555,
  },
  {
    name: "OEBPS/Styles/stylesheet.css",
    size: 1265,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_000.html",
    size: 1369,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_001.html",
    size: 3471,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_002.html",
    size: 8310,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_003.html",
    size: 8751,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_004.html",
    size: 19022,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_005.html",
    size: 14742,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_006.html",
    size: 19379,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_007.html",
    size: 22526,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_008.html",
    size: 7766,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_009.html",
    size: 10404,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_010.html",
    size: 6799,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_011.html",
    size: 11955,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_012.html",
    size: 26191,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_013.html",
    size: 33719,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_014.html",
    size: 22644,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_015.html",
    size: 16225,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_016.html",
    size: 26171,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_017.html",
    size: 7612,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_018.html",
    size: 17091,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_019.html",
    size: 28959,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_020.html",
    size: 3792,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_021.html",
    size: 1188,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_022.html",
    size: 6289,
  },
  {
    name: "OEBPS/Text/A_History_of_Mindfulness_EBOOK_split_023.html",
    size: 26522,
  },
  {
    name: "OEBPS/Text/titlepage.xhtml",
    size: 473,
  },
  { name: "OEBPS/toc.ncx", size: 3885 },
  {
    name: "a this is a very very very very very very very very long file name.pdf",
    size: 1234567,
  },
];

let nFiles = 10134;
for (let i = 0; i < nFiles; i++) {
  const size = Math.trunc(Math.random() * 999999);
  const e = {
    name: "foo" + i,
    size: size,
  };
  testFiles.push(e);
}

import * as filepath from "./filepath";

import {
  fmtSize,
  getObjectValue,
  len,
  nanoid,
  throwIf,
  trimSuffix,
} from "./util";

import { showMessage } from "./message";
import { writable } from "svelte/store";
import { xhrGetAsBlob } from "./http";

/** @typedef {import('./fsentry').FSEntry} FSEntry */

/** @typedef {"local:" | "s3:" | "dropbox:" | "onedrive:" | "backblaze:" | "testfiles:" } FSType */

/** @type {FSType} */
export const fsTypeS3 = "s3:";
/** @type {FSType} */
export const fsTypeBackBlaze = "backblaze:";
/** @type {FSType} */
export const fsTypeDropbox = "dropbox:";
/** @type {FSType} */
export const fsTypeOneDrive = "onedrive:";
/** @type {FSType} */
export const fsTypeLocal = "local:";
/** @type {FSType} */
export const fsTypeTestFiles = "testfiles:";

/** @type {FSType[]} */
let allFsTypes = [
  fsTypeS3,
  fsTypeDropbox,
  fsTypeOneDrive,
  fsTypeBackBlaze,
  fsTypeLocal,
  fsTypeTestFiles,
];

/**
 * @param {string} prefix
 * @returns {string}
 */
function newFsID(prefix) {
  return prefix + nanoid(8);
}

/**
 * @param {string} fsID
 * @returns {FSType}
 */
export function fsTypeFromID(fsID) {
  for (let type of allFsTypes) {
    if (fsID.startsWith(type)) {
      return type;
    }
  }
  throw new Error(`Unknown fsID: ${fsID}`);
}

/**
 * @param {FSConfig} fsConfig
 * @returns {FSType}
 */
export function fsTypeFromFSConfig(fsConfig) {
  return fsTypeFromID(fsConfig.fsID);
}

/**
  @typedef {{
    fsID: string,  // unique id of this config
    name: string,
  }} FSConfigBase
*/

/**
 @typedef {FSConfigBase & {
   access: string,
   secret: string,
   bucket: string,
   endpoint: string
 }} S3Config
*/

/** @returns {S3Config} */
export function createS3Config() {
  return {
    fsID: newFsID(fsTypeS3),
    name: "",

    access: "",
    secret: "",
    bucket: "",
    endpoint: "",
  };
}

/**
 * @param {S3Config} c1
 * @param {S3Config} c2
 * @returns {boolean}
 */
function s3ConfigEq(c1, c2) {
  return (
    c1.access == c2.access &&
    c1.secret == c2.secret &&
    c1.bucket == c2.bucket &&
    c1.endpoint == c2.endpoint
  );
}

/** 
  @typedef {FSConfigBase & {
   accessToken: string,
   refreshToken: string,
   email: string,
   name: string
 }} DropboxConfig
*/

/** @returns {DropboxConfig} */
export function createDropboxConfig() {
  return {
    fsID: fsTypeDropbox, // only one dropbox connection
    name: "DropBox",
    accessToken: "",
    refreshToken: "",
    email: "",
  };
}

/** 
  @typedef {FSConfigBase & {
   accessToken: string,
   refreshToken: string,
   tokenExpires: string, // Date as string
   email: string,
 }} OneDriveConfig
*/

/** @returns {OneDriveConfig} */
export function createOneDriveConfig() {
  return {
    fsID: fsTypeOneDrive, // only one onedrive connection
    name: "OneDrive",
    accessToken: "",
    refreshToken: "",
    tokenExpires: "",
    email: "",
  };
}

/** 
  @typedef {FSConfigBase & {
    dirHandle: FileSystemDirectoryHandle  // not persisted
}} LocalConfig */

/** @returns {LocalConfig} */
export function createLocalConfig(dirHandle, name) {
  return {
    fsID: newFsID(fsTypeLocal),
    name: name,
    dirHandle: dirHandle,
  };
}

/**
 * @param {LocalConfig} c1
 * @param {LocalConfig} c2
 * @returns {boolean}
 */
export function localConfigEq(c1, c2) {
  return c1.dirHandle == c2.dirHandle;
}

/** 
  @typedef {FSConfigBase & {
    appKeyId: string,
    appKey: string,
    bucketId: string,
    bucketName: string
}} BackBlazeConfig */

/** @returns {BackBlazeConfig} */
export function createBackBlazeConfig() {
  return {
    fsID: newFsID(fsTypeBackBlaze),
    name: "BackBlaze",
    appKeyId: "",
    appKey: "",
    bucketId: "",
    bucketName: "",
  };
}

/**
 * @param {BackBlazeConfig} c1
 * @param {BackBlazeConfig} c2
 * @returns {boolean}
 */
export function backBlazeConfigEq(c1, c2) {
  return (
    c1.appKeyId == c2.appKeyId &&
    c1.appKey == c2.appKey &&
    c1.bucketId == c2.bucketId &&
    c1.bucketName == c2.bucketName
  );
}

/** 
  @typedef {FSConfigBase & {
    // no additional fields needed for test files
}} TestFilesConfig */

/** @returns {TestFilesConfig} */
export function createTestFilesConfig() {
  return {
    fsID: fsTypeTestFiles, // only one testfiles connection
    name: "Test Files",
  };
}

/**
 * @param {FSConfig} c1
 * @param {FSConfig} c2
 * @returns {boolean}
 */
export function fsConfigEq(c1, c2) {
  // for configs that only have one possible instance, we can just compare fsID
  if (c1.fsID === c2.fsID) {
    return true;
  }
  let fs1Type = fsTypeFromID(c1.fsID);
  let fs2Type = fsTypeFromID(c2.fsID);
  if (fs1Type !== fs2Type) {
    return false;
  }
  switch (fs1Type) {
    case fsTypeS3:
      let s3c1 = /** @type {S3Config} */ (c1);
      let s3c2 = /** @type {S3Config} */ (c2);
      return s3ConfigEq(s3c1, s3c2);
    case fsTypeBackBlaze:
      let bb1 = /** @type {BackBlazeConfig} */ (c1);
      let bb2 = /** @type {BackBlazeConfig} */ (c2);
      return backBlazeConfigEq(bb1, bb2);
    case fsTypeLocal:
      let l1 = /** @type {LocalConfig} */ (c1);
      let l2 = /** @type {LocalConfig} */ (c2);
      return localConfigEq(l1, l2);
  }
  return false;
}

/**
 * @typedef {S3Config | DropboxConfig | OneDriveConfig | LocalConfig | BackBlazeConfig | TestFilesConfig} FSConfig
 */

// re-using this in mulitple contexts for simplicity
export class FileInfo {
  name = "";
  size = 0;
  /** @type {File|null} */
  file = null;
  path = "";
  // when uploading, this is destination path
  dstPath = "";
}

/**
 * Copy properties from objet to FSEntry
 * @param {string[]} props
 * @param {Object} o
 * @param {Array} e
 */
export function fsCopyProps(props, o, e) {
  for (let propName of props) {
    let v = getObjectValue(o, propName);
    if (v) {
      e.push(propName, v);
    }
  }
}

class DirAndFileFSEntry {
  /** @type {FSEntry} */
  dir = null;
  /** @type {FSEntry} */
  entry = null;
  constructor(dir) {
    this.dir = dir;
  }
}

/**
 * @param {FS} fs
 * @returns {string}
 */
export function fsFullName(fs) {
  let conf = fs.config;
  let name = conf.name;
  let fsType = fsTypeFromFSConfig(conf);
  switch (fsType) {
    case fsTypeS3:
      let s3Config = /** @type {S3Config} */ (conf);
      name = "s3 (" + name + ")";
      // s3Config.bucket + "." + s3Config.endpoint;
      break;
    case fsTypeBackBlaze:
      let bbConfig = /** @type {BackBlazeConfig} */ (conf);
      name = "BackBlaze (" + name + ")";
      break;
    case fsTypeLocal:
      name = "Local Folder (" + name + ")";
      break;
    case fsTypeDropbox:
      name = "DropBox (" + name + ")";
      break;
    case fsTypeOneDrive:
      name = "OneDrive (" + name + ")";
      break;
  }
  return name;
}

/**
 * @param {FSConfig} fsConfig
 * @returns {string}
 */
export function fsConfigFullName(fsConfig) {
  let name = fsConfig.name;
  let fsType = fsTypeFromFSConfig(fsConfig);
  switch (fsType) {
    case fsTypeS3:
      let s3Config = /** @type {S3Config} */ (fsConfig);
      if (name == "") {
        name = "S3 (" + s3Config.bucket + ")";
      }
      break;

    case fsTypeBackBlaze:
      let bbConfig = /** @type {BackBlazeConfig} */ (fsConfig);
      name = "BackBlaze (" + bbConfig.bucketName + ")";
      break;

    case fsTypeLocal:
      name = "Local Folder (" + name + ")";
      break;
  }
  return name;
}

export class FS {
  /** @type {FSConfig} */
  config;
  /** @type {Map<string, FSEntry>} */
  dirs = new Map();
  filesCount = 0;
  dummyProgress = writable("");

  /**
   * @param {FSConfig} config
   */
  constructor(config) {
    this.config = config;
  }

  /** @type {string}  */
  get name() {
    return this.config.name;
  }

  /** @type {FSType} */
  get type() {
    return fsTypeFromFSConfig(this.config);
  }

  /**
   * @param {string} path
   * @returns {DirAndFileFSEntry | null}
   */
  findDirAndFileFSEntry(path) {
    const isDir = path.endsWith("/");
    if (isDir && path != "/") {
      path = path.substring(0, len(path) - 1);
    }

    const parts = filepath.split(path);
    let dirName = parts.dir;
    let entryName = parts.name;

    const dir = this.dirs.get(dirName);
    if (!dir) {
      return null;
    }
    let res = new DirAndFileFSEntry(dir);
    if (entryName !== "") {
      for (let e of dir.fsentries) {
        if (e.name == entryName) {
          res.entry = e;
          return res;
        }
      }
    }
    return res;
  }

  /**
   * @param {FSEntry} fsentry
   */
  calcDirLastMod(fsentry) {
    // the api doesn't provide lastMod time for directories so we
    // calculate it as the lastMod of newest file in a directory
    let lastMod = 0;
    let entries = fsentry.fsentries;
    for (let e of entries) {
      if (e.isDir) {
        continue;
      }
      if (e.lastMod > lastMod) {
        lastMod = e.lastMod;
      }
    }
    if (lastMod == 0) {
      lastMod = Date.now();
    }
    fsentry.lastMod = lastMod;
  }

  /**
   * @param {FSEntry} fsentry
   */
  cacheDir(fsentry) {
    let dirPath = fsentry.name;
    throwIf(!fsentry.isDir, `${dirPath} is not a dir`);
    throwIf(!dirPath.startsWith("/"), `${dirPath} doesn't start with '/'`);
    console.log(`FS.cacheDir for dir: '${dirPath}'`);

    // this.calcDirLastMod(fsentry);

    this.filesCount += fsentry.filesCount();
    let old = this.dirs.get(dirPath);
    if (old) {
      this.filesCount -= old.filesCount();
    }
    this.dirs.set(dirPath, fsentry);
  }

  /**
   * @param {string} dirPath
   */
  removeCachedDir(dirPath) {
    console.log(`FS.removeCachedDir: ${dirPath}`);
    let e = this.dirs.get(dirPath);
    this.filesCount -= e.filesCount();
    this.dirs.delete(dirPath);
  }

  /**
   * @param {string} dirPath
   */
  removeCachedDirRecur(dirPath) {
    this.removeCachedDir(dirPath);
    // remove all sub-directories of dirPath
    let prefix = dirPath + "/";
    let dirsToRemove = [];
    for (let dir of this.dirs.keys()) {
      if (dir.startsWith(prefix)) {
        dirsToRemove.push(dir);
      }
    }
    for (let dir of dirsToRemove) {
      this.removeCachedDir(dir);
    }
  }

  /**
   * @param {string} path
   */
  findEntries(path) {
    let dn = filepath.split(path);
    let dir = dn.dir;
    if (dir == "") {
      dir = "/";
    }
    const de = this.dirs.get(dir);
    if (!de) {
      throw new Error(`Didn't find ${dir} parent dir for path ${path} `);
    }
    if (path == "/") {
      return [de];
    }

    let entries = [];
    for (let e of de.fsentries) {
      if (e.name == dn.name) {
        entries.push(e);
      }
    }
    if (len(entries) == 0) {
      throw new Error(`Didn't find entry ${dn.name} in dir ${dn.dir} `);
    }
    return entries;
  }

  /**
   * @param {string} path
   * @param {boolean} isDir
   */
  findFirstEntryOfType(path, isDir) {
    let entries = this.findEntries(path);
    for (let e of entries) {
      if (e.isDir == isDir) {
        return e;
      }
    }
    throw new Error(`Didn't find enry for dir ${path}`);
  }

  skipReadParentDirs = false;
  async readParentDirs(dirPath, force, progress) {
    if (this.skipReadParentDirs) {
      return;
    }
    this.skipReadParentDirs = true;
    let dirs = [];
    dirPath = filepath.dir(dirPath);
    while (dirPath != "/") {
      dirs.push(dirPath);
      dirPath = filepath.dir(dirPath);
    }
    dirs.push("/");

    let n = len(dirs);
    for (let i = 0; i < n; i++) {
      let dir = dirs[n - i - 1];
      await this.readDir(dir, false, progress);
    }
    this.skipReadParentDirs = false;
  }

  /**
   * read fs entries in a given directory
   * @param {string} dirPath - directory name
   * @param {boolean} force - if true, don't use cached values
   * @param {import("svelte/store").Writable} progress
   * @return {Promise<FSEntry[]>}
   */
  async readDir(dirPath, force, progress) {
    throw new Error(`readDir() not implemented for ${this.type}`);
  }

  /**
   * get publicly visible URL
   * @param {string} path
   * @returns {Promise<string>}
   */
  async getPublicURL(path) {
    throw new Error(`getPublicURL() not implemented for${this.type}`);
  }

  /**
   * Generic download function that works using getPublicURL
   * Some filesystems might implement optimized version
   * @param {string} path
   * @param {import("./http").HttpProgress} httpProgress
   * @returns {Promise<Blob>}
   */
  async downloadFile(path, httpProgress) {
    let uri = await this.getPublicURL(path);
    let blob = await xhrGetAsBlob(uri, httpProgress);
    return blob;
  }

  /**
   * @param {string} path
   * @param {Blob} file
   * @param {import("./http").HttpProgress} httpProgress
   */
  async uploadFile(path, file, httpProgress) {
    throw new Error(`uploadFile() not implemented for ${this.type}`);
  }

  /**
   * upload files to this filesystem
   * @param {FileInfo[]} files - files to upload
   * @param {import("svelte/store").Writable} progress
   */
  async uploadFiles(files, progress) {
    console.log("local.uploadFiles() files:", files);
    for (let fi of files) {
      let msg = `uploading ${fi.path}`;
      progress.set(msg);
      console.log("uploadFiles:", msg);

      function onProgress(size, totalSize, durMs) {
        console.log(
          "onProgress: size:",
          size,
          "totalSize:",
          totalSize,
          "durMs:",
          durMs
        );
        msg = `uploading ${fi.path} ${fmtSize(size)}`;
        if (totalSize > size) {
          msg += ` of ${fmtSize(totalSize)}`;
        }
        msg += ` in ${durMs} ms`;
        progress.set(msg);
      }
      progress.set(msg);

      await this.uploadFile(fi.dstPath, fi.file, onProgress);
    }
  }

  /**
   * rename a file
   * @param {string} path
   * @param {string} newPath
   */
  async rename(path, newPath) {
    throw new Error(`rename() not implemented for ${this.type}`);
  }

  /**
   * create a new directory
   * @param {string} dirPath - full path for the directory
   */
  async createDirectory(dirPath) {
    throw new Error(`createDirectory() not implemented for ${this.type}`);
  }

  /**
   * @param {string} path
   */
  async deleteFile(path) {
    throw new Error(`deleteFile() not implemented for ${this.type}`);
  }

  /**
   * delete files and directories in this filesystem
   * @param {string[]} paths
   * @param {import("svelte/store").Writable} progress
   */
  async deleteFiles(paths, progress) {
    for (let path of paths) {
      progress.set(`deleting ${path}`);
      await this.deleteFile(path);
    }
  }

  /**
   * pre-cache file listing by scanning directories
   * recursively
   * @param {string[]} toRead
   */
  async readDirRecur(toRead) {
    let n = 0;
    while (len(toRead) > 0) {
      let dirPath = toRead.shift();
      let msg = `caching dir ${dirPath}, ${this.dirs.size} dirs, ${this.filesCount} files`;
      let removeMsg = showMessage(msg);
      n++;
      console.log(
        `readDirRecur: ${dirPath}, dirs read: ${n}, dirs left: ${len(toRead)}`
      );
      try {
        let dir = this.dirs.get(dirPath);
        let entries = dir ? dir.fsentries : null;
        if (!entries) {
          entries = await this.readDir(dirPath, false, this.dummyProgress);
        }
        removeMsg();
        for (let e of entries) {
          if (!e.isDir) {
            continue;
          }
          let path = filepath.join(dirPath, e.name);
          toRead.push(path);
        }
      } catch (err) {
        console.error(`readDirRecur: `, err);
        removeMsg();
        return;
      }
    }
  }
}

/**
 * get paths for files in a directory, relative to directory name
 * recursively if needed
 * @param {FS} fs
 * @param {string} dir
 * @returns {Promise<string[]>}
 */
export async function getDirFilesRelativePath(fs, dir, recursive) {
  let toRead = [dir];
  let res = [];
  let dummyProgress = writable("");
  let relativeStarts = len(dir);

  while (len(toRead) > 0) {
    let dirPath = toRead.shift();
    let entries = await fs.readDir(dirPath, true, dummyProgress);
    for (let e of entries) {
      let path = filepath.join(dirPath, e.name);
      if (!e.isDir) {
        path = path.substring(relativeStarts);
        path = trimSuffix(path, "/");
        res.push(path);
      } else if (recursive) {
        toRead.push(path);
      }
    }
  }

  return res;
}

export class FSDirState {
  /** @type {FS|null} */
  fs = null;
  currDir = "";
  // if we're reading directory in the background,
  // this is the directory we're reading
  dirInProgress = "";
  /** @type {FSEntry[]} */
  entries = [];
  isValidDir = true;

  onReloadCurrentDir;

  triggerReloadCurrentDir() {
    if (this.onReloadCurrentDir) {
      this.onReloadCurrentDir();
    }
  }

  /**
   * @param {FS} fs
   * @param {string} dir
   */
  constructor(fs, dir) {
    this.fs = fs;
    this.currDir = dir;
  }
}

<script>
  import { run } from 'svelte/legacy';

  import { getContext, onMount } from "svelte";
  import DialogBase from "./DialogBase.svelte";

  import * as filepath from "./filepath";

  // if true, we can only change file name,
  // not directory
  /** @type {{path?: string, ignoreCase?: boolean, destSameDir?: boolean, open?: boolean}} */
  let {
    path = "",
    ignoreCase = false,
    destSameDir = false,
    open = $bindable(false)
  } = $props();

  console.log("DialogRename: path:", path);
  let inputEl = $state();

  let fnRename = getContext("fnRename");

  // console.log("DialogRename: path=", path);
  let dir = $state("");
  let fileName = $state("");
  let renameDisabled = $state(true);
  let newPath = $state("");
  let suff = $state("");

  let errorMsg = $state("");

  function setFocus() {
    let s = destSameDir ? fileName : path;
    let idx = filepath.indexOfExt(s);
    if (inputEl) {
      inputEl.focus();
      if (idx >= 0) {
        inputEl.setSelectionRange(idx, idx);
      }
    }
  }
  onMount(() => {
    setFocus();
    let parts = filepath.split(path);
    dir = parts.dir;
    fileName = parts.name;
    suff = dir == "/" ? "" : "/";
  });


  function buildErrorMessage(newPath) {
    renameDisabled = false;
    if (newPath == "") {
      renameDisabled = true;
      return "name can't be empty";
    }
    let msg = "destination path must be different";
    if (ignoreCase) {
      msg = "destination name must be different, case insensitive";
    }
    if (newPath == path) {
      renameDisabled = true;
      return msg;
    }
    if (ignoreCase && newPath.toLowerCase() == path.toLowerCase()) {
      renameDisabled = true;
      return msg;
    }
    if (destSameDir) {
      let srcDir = filepath.dir(path);
      let dstDir = filepath.dir(newPath);
      if (srcDir != dstDir) {
        msg = "must be in same directory";
        renameDisabled = true;
        return msg;
      }
    }
    return "";
  }

  function buildWarningMessage(newPath) {
    if (newPath.endsWith(" ")) {
      return "<span>Warning: file name ends with spaces</span>";
    }
    const ext1 = filepath.ext(path);
    const ext2 = filepath.ext(newPath);
    if (ext1 != ext2) {
      return `<span>Warning: changing extension: <b>${ext1}</b> to <b>${ext2}</b></span>`;
    }

    return "";
  }

  /**
   * @param {KeyboardEvent} ev
   */
  function handleKeyDown(ev) {
    console.log("DialogRename: handleKeyDown ev:", ev);
    let isEnter = ev.key === "Enter";
    if (isEnter && !errorMsg) {
      // dispatch("fmnewdir", fullNewPath);
      ev.preventDefault();
      ev.stopPropagation();
      fnRename({ path, newPath });
      return;
    }
  }

  function close() {
    open = false;
  }
  run(() => {
    newPath = filepath.join(dir, fileName);
  });
  run(() => {
    errorMsg = buildErrorMessage(newPath);
  });
  let warningMsg = $derived(buildWarningMessage(newPath));
</script>

<DialogBase bind:open title="Rename">
  {#snippet main()}
    <div  class="flex flex-col" onkeydown={handleKeyDown} tabindex="0" role="textbox">
      <div class="mt-2">{path}</div>
      <div class="font-semibold mt-2">to:</div>
      {#if destSameDir}
        <div class="flex items-baseline mt-2">
          <div>{dir}{suff}</div>
          <input
            type="text"
            autocomplete="off"
            spellcheck="false"
            class="border-b py-1 focus:outline-none focus:border-gray-400 w-full"
            bind:value={fileName}
            bind:this={inputEl}
          />
        </div>
      {:else}
        <div class="flex items-baseline mt-2">
          <input
            type="text"
            autocomplete="off"
            spellcheck="false"
            class="border-b py-1 focus:outline-none focus:border-gray-400 w-full"
            bind:value={newPath}
            bind:this={inputEl}
          />
        </div>
      {/if}
      {#if errorMsg}
        <div
          class="text-sm text-red-500 mt-2 min-w-[32rem] min-h-[1.5em] max-w-full"
        >
          {errorMsg}
        </div>
      {:else}
        <div
          class="text-sm text-gray-600 mt-2 min-w-[32rem] min-h-[1.5em] max-w-full"
        >
          {@html warningMsg}
        </div>
      {/if}
    </div>
  {/snippet}

  {#snippet bottom()}
    <div  class="flex justify-end">
      <button
        class="dlg-btn"
        onclick={close}>Cancel</button
      >
      <button
        disabled={renameDisabled}
        class="ml-8 dlg-btn font-semibold"
        onclick={fnRename({ path, newPath })}>Rename</button
      >
    </div>
  {/snippet}
</DialogBase>

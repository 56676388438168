<script>
  import MenuItems from "./MenuItems.svelte";
  import { len } from "./util";

  /** @type {{
    menu?: import("./MenuItem").MenuItem[]
  }} */
  let { menu = [] } = $props();

  function itemClicked(item) {
    // console.log("MenuItems: itemClicked:", item);
    if (item.handler) {
      item.handler();
    }
  }
</script>

<div
  class="select-none bg-white min-w-[120px] px-0 py-0 border-2 border-gray-200 drop-shadow-lg"
>
  {#each menu as item}
    {#if item.isSeparator()}
      <hr class="w-full m-0 border-1 border-gray-300" />
    {:else if len(item.items) > 0}
      <div
        class="submenu-wrapper w-full flex flex-row justify-between text-sm hover:bg-gray-100 py-1 px-4"
      >
        <div>
          {item.label}
        </div>
        <div>&gt;</div>
        <div class="submenu relative left-0">
          <MenuItems menu={item.items} />
        </div>
      </div>
    {:else}
      <button
        class="flex w-full whitespace-nowrap justify-between place-items-center text-left text-sm hover:bg-gray-100 py-1 px-4"
        onclick={() => itemClicked(item)}
      >
        <div class="max-w-[32ch] truncate">
          {@html item.label}
        </div>
        {#if item.shortcut}
          <div class="ml-4 text-gray-600 text-sm">{item.shortcut}</div>
        {/if}
      </button>
    {/if}
  {/each}
</div>

<style>
  .submenu {
    display: none;
  }
  .submenu-wrapper:hover > .submenu {
    display: flex;
  }
</style>

import { len } from "../util";

export class NodesCollection {
  /** @type {HTMLElement[]} */
  nodes = [];
  cachedMax = [];
  dimensions = 1;

  /**
   * @param {number} dimensions
   * @param {number} estimatedCount
   */
  clear(dimensions, estimatedCount) {
    this.dimensions = dimensions;
    this.nodes.length = dimensions * estimatedCount;
    for (let i = 0; i < dimensions; i++) {
      this.cachedMax[i] = -1;
    }
  }

  /**
   * @param {HTMLElement} node
   * @param {number} dim
   */
  rememberNode(node, idx, dim) {
    let n = idx * this.dimensions + dim;
    this.nodes[n] = node;
    this.cachedMax[dim] = -1;
  }

  /**
   * @param {number} dim
   */
  maxWidth(dim) {
    let max = this.cachedMax[dim];
    if (max > 0) {
      return max;
    }
    let nodes = this.nodes;
    const n = len(nodes);
    if (n == 0) {
      return 0;
    }
    // TODO: could use IntersectionObserver to maybe save 300 ms on 10k items
    // https://toruskit.com/blog/how-to-get-element-bounds-without-reflow/
    let idx = 0;
    while (idx < n) {
      let dx = nodes[idx + dim].clientWidth;
      if (dx > max) {
        max = dx;
      }
      idx += this.dimensions;
    }
    this.cachedMax[dim] = max;
    return max;
  }
}

/**
 * @param {HTMLElement} node
 * @param {{nodes: NodesCollection, dim: number, idx: number}} data
 */
export function remember(node, data) {
  data.nodes.rememberNode(node, data.idx, data.dim);
}

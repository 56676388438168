/** @typedef {import("./fs").TestFilesConfig} TestFilesConfig */

import { testFiles } from "./testfiles";
import { split } from "./filepath";
import { sleep } from "./util";
import { FS } from "./fs";
import { FSEntry, newDirFSEntry } from "./fsentry";

class FileSystemTest extends FS {
  /**
   * @param {TestFilesConfig} config
   */
  constructor(config) {
    super(config);
  }

  async readDir(dirPath, force, progress) {
    let e = this.dirs.get(dirPath);
    if (!e) {
      throw new Error("no entries");
    }
    return e.fsentries;
  }

  async deleteOne(path) {
    // TODO: implement me
  }

  async deleteFiles(paths, progress) {
    progress.set(`deleting files`);
    await sleep(1000);
    for (let p of paths) {
      await this.deleteOne(p);
    }
  }
}

/**
 * @param {TestFilesConfig} config
 * @returns {FS}
 */
export function makeFileSystemTest(config) {
  let flatFiles = testFiles;
  /** @type {Map<string, FSEntry>} */
  let dirs = new Map();
  for (let f of flatFiles) {
    const size = f.size;
    let dn = split(f.name);
    let dirPath = dn.dir;

    if (!dirPath.startsWith("/")) {
      dirPath = "/" + dirPath;
    }
    let de = dirs.get(dirPath);
    if (!de) {
      de = newDirFSEntry(dirPath);
      dirs.set(dirPath, de);
    }
    let meta = [null, dn.name, size, 0, null];
    const e = FSEntry.fromMeta(meta);
    de.fsentries.push(e);
  }
  // add sub-directories
  for (let dirPath of dirs.keys()) {
    console.log(`dirPath: ${dirPath}`);
    if (dirPath == "/") {
      continue;
    }
    let dn = split(dirPath);
    let de = dirs.get(dn.dir);
    let e = newDirFSEntry(dn.name);
    de.fsentries.push(e);
  }
  let fs = new FileSystemTest(config);
  fs.dirs = dirs;
  return fs;
}

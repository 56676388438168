<script>
  import { tooltip } from "./Tooltip.svelte";
  import Bookmarks from "./Bookmarks.svelte";

  import ArrowLeft from "./icons/ArrowLeft.svelte";
  import ArrowRight from "./icons/ArrowRight.svelte";
  import ArrowUp from "./icons/ArrowUp.svelte";
  import Star from "./icons/Star.svelte";

  
  
  
  
  /** @type {{path?: string, currBookmark: import("./bookmarks").Bookmark, addtobookmarks: () => void, fmdirup: () => void, gotobookmark: (b) => void}} */
  let {
    path = "",
    currBookmark,
    addtobookmarks,
    fmdirup,
    gotobookmark
  } = $props();

  let showBookmarks = $state(false);
</script>

<div class="grow-0 flex items-center px-1 pl-2 py-1 bg-gray-300">
  <!-- left (back) -->
  <div use:tooltip={"history back (not yet implemented)"}>
    <ArrowLeft class="text-gray-700 hover:bg-gray-200 cursor-pointer" />
  </div>

  <!-- right (forward) -->
  <div class="ml-2" use:tooltip={"history nav (not yet implemented)"}>
    <ArrowRight class="text-gray-700 hover:bg-gray-200 cursor-pointer" />
  </div>

  <!-- bookmarks -->
  <button
    class="ml-2 relative"
    onclick={() => {
      console.log("showBookmarks");
      showBookmarks = true;
    }}
  >
    <div use:tooltip={"Bookmarks"}>
      <Star class="text-gray-700 hover:bg-gray-200 cursor-pointer" />
    </div>
    {#if showBookmarks}
      <Bookmarks
        {currBookmark}
        dismiss={() => (showBookmarks = false)}
        {gotobookmark}
        {addtobookmarks}
      />
    {/if}
  </button>

  <!-- up  -->
  <button onclick={() => fmdirup()} use:tooltip={"go up"} class="ml-2">
    <ArrowUp class="text-gray-700 hover:bg-gray-200 cursor-pointer" />
  </button>

  <div class="ml-2 flex-grow px-2 py-1 bg-gray-50 text-xs truncate">{path}</div>
</div>

<script>
  import { fetchStore } from "./http";
  import { fmtSize } from "./util";

  /** @type {{url: any, fileName?: string}} */
  let { url, fileName = "" } = $props();

  let msg = $state(`Downloading: ${fileName}`);

  function onProgress(size, totalSize, durMs) {
    const sizeStr = fmtSize(size);
    if (totalSize == 0) {
      msg = `Download: ${fileName} ${sizeStr} int ${durMs} ms`;
    } else {
      const totalStr = fmtSize(totalSize);
      msg = `Download: ${fileName} ${sizeStr} of ${totalStr}, in ${durMs} ms`;
    }
  }

  const [data, loading, error] = fetchStore(url, onProgress);
</script>

<div
  class="bg-white overflow-auto mx-auto self-center flex flex-col px-2 py-2 focus:outline-none min-w-[50%] max-w-[80%] min-h-[50%] max-h-[80%]"
>
  {#if $loading}
    <div class="self-center my-auto">
      {msg}
    </div>
  {:else if $error}
    <div class="self-center my-auto text-red-500">
      Error: {$error}
    </div>
  {:else}
    <pre>{$data}</pre>
  {/if}
</div>

import { isDev, len, throwIf, timeSince } from "./util";

import { getUserID } from "./user";
import { writable } from "svelte/store";

var enc = encodeURIComponent;

/**
 * @param {string} url
 * @param {HttpProgress} onProgress
 * @returns {Promise<Blob>}
 */
export async function getAsBlob(url, onProgress) {
  const startTime = new Date();
  const rsp = await fetch(url);
  const r = rsp.body.getReader();
  const totalSize = +rsp.headers.get("Content-Length");
  let size = 0;
  let chunks = [];
  while (true) {
    const { done, value } = await r.read();
    if (done) {
      break;
    }
    chunks.push(value);
    size += value.length;
    const durMs = timeSince(startTime);
    onProgress(size, totalSize, durMs);
  }
  const blob = new Blob(chunks);
  return blob;
}

/**
 * @param {string} url
 * @param {HttpProgress} onProgress
 * @returns {Promise<string>}
 */
export async function getAsText(url, onProgress) {
  const blob = await getAsBlob(url, onProgress);
  const dec = new TextDecoder("utf-8");
  return dec.decode(await blob.arrayBuffer());
}

/**
 * @param {string} url
 * @param {HttpProgress} onProgress
 * @returns {Promise<Object>}
 */
export async function getAsJSON(url, onProgress) {
  const s = await getAsText(url, onProgress);
  return JSON.parse(s);
}

// TODO: seems hackish. X-User is only for identifying user when talking to
// our backend. It breaks CORS when used with e.g. dropbox urls
function makeFetchOpts(uri) {
  let userID = getUserID();
  if (!userID) {
    return {};
  }
  let apiCall = uri.startsWith("/api/");
  if (!apiCall) {
    return {};
  }
  return {
    headers: {
      "X-User": userID,
    },
  };
}

/**
 * @param {string} uri
 * @returns {Promise<Response>}
 */
export async function apiFetch(uri) {
  let opts = makeFetchOpts(uri);
  return fetch(uri, opts);
}

/**
 * Note: not async because we don't care
 * @param {string} msg
 */
export function telegramNotify(msg) {
  if (isDev()) {
    return;
  }
  const uri = `/api/telegram/notify?msg=${enc(msg)}`;
  apiFetch(uri);
}

/**
 * abstracts capturing events and also only needs to silence
 * warning about posthog. once
 * @param {string} name
 * @param {Object} arg
 */
export function captureEvent(name, arg) {
  let url = "/api/le?name=" + enc(name);
  if (arg) {
    for (let k of Object.keys(arg)) {
      url = url + `&${enc(k)}=${enc(arg[k])}`;
    }
  }
  apiFetch(url);

  if (isDev()) {
    // don't capture events from my dev usage
    return;
  }
  // @ts-ignore
  posthog.capture(name, arg);
}

/**
 * @param {string} url
 * @param {HttpProgress} onProgress
 */
export function fetchStore(url, onProgress) {
  const loading = writable(false);
  const error = writable("");
  const data = writable({});

  async function get() {
    loading.set(true);
    error.set("");
    try {
      const s = await getAsText(url, onProgress);
      data.set(s);
    } catch (e) {
      let msg = `Fetching ${url} failed with ${e.toString()}`;
      error.set(msg);
    }
    loading.set(false);
  }

  get();

  return [data, loading, error];
}

/**
 * @param {string} uri
 * @param {File} file
 */
export async function puthWithFetch(uri, file) {
  let size = file.size;
  console.log(`puthWithFetch: uri=${uri}, size=${size}`);
  let rsp = await fetch(uri, {
    method: "PUT",
    // mode: "no-cors",
    headers: {
      // "Access-Control-Allow-Origin": "*", // Required for CORS support to work
      // "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
      // doesn't support chunked encoding so must provide size
      // "Content-Length": size,
    },
    body: file,
    // body: body,
  });
  await throwIfFetchFailed(rsp);
  let js = await rsp.json();
  console.log(`puthWithFetch: '${js}'`);
  return js;
}

/**
 * @param {Response} rsp
 */
export async function throwIfFetchFailed(rsp) {
  if (rsp.status >= 200 && rsp.status < 300) {
    return;
  }
  //let s = await rsp.text();
  //let s = await rsp.json();
  //console.log("s:", s);
  const msg = `${rsp.url} failed with ${rsp.status} ${rsp.statusText}.`;
  throw new Error(msg);
}

/**
 * @callback HttpProgress
 * @param {number} size
 * @param {number} totalSize
 * @param {number} durMs
 */

export class XhrOpts {
  method = "GET";
  /** @type {String|Object|FormData} */
  params;
  /** @type {Blob|string} */
  data;
  /** @type {XMLHttpRequestResponseType} */
  responseType = "";
  headers = {};
  /** @type {HttpProgress} */
  httpProgress;
}

/**
 * Native XMLHttpRequest as Promise
 * @see https://stackoverflow.com/a/30008115/933951
 * @see https://javascript.info/xmlhttprequest
 * @param {string} url
 * @param {XhrOpts} opts - Options object
 * @return {Promise<XMLHttpRequest>} Promise object of XHR
 */
export function xhrAsync(url, opts) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    let params = opts.params;

    let lcMethod = opts.method.toLowerCase();

    let paramsInURL =
      lcMethod == "get" || lcMethod == "head" || lcMethod == "delete";
    // We'll need to stringify if we've been given an object
    // If we have a string, this is skipped.

    if (params && typeof params === `object` && !(params instanceof FormData)) {
      let a = [];
      for (let [k, v] of Object.entries(params)) {
        a.push(`${enc(k)}=${enc(v)}`);
      }
      params = a.join(`&`);
    }
    if (paramsInURL && len(params) > 0) {
      url = url + "?" + params;
      params = null;
    }

    xhr.open(opts.method, url);

    xhr.responseType = opts.responseType;
    if (xhr.responseType == "" && lcMethod == "get") {
      xhr.responseType = "blob";
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      }
    };

    xhr.onerror = () => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      });
    };

    if (opts.headers) {
      Object.keys(opts.headers).forEach((key) => {
        xhr.setRequestHeader(key, opts.headers[key]);
      });
    }

    const startTime = new Date();

    function sendProgress(ev, onProgress) {
      console.log("sendProgress:", ev);
      if (!onProgress) {
        return;
      }
      const size = ev.loaded;
      let totalSize = 0;
      if (ev.lengthComputable) {
        totalSize = ev.total;
      }
      const durMs = timeSince(startTime);
      onProgress(size, totalSize, durMs);
    }

    if (opts.method == "PUT" || opts.method == "POST") {
      xhr.upload.onprogress = function (ev) {
        // console.log("xhr.upload.onprogress:", ev);
        sendProgress(ev, opts.httpProgress);
      };
    } else {
      xhr.onprogress = function (ev) {
        // console.log("xhr.onprogress:", ev);
        sendProgress(ev, opts.httpProgress);
      };
    }

    if (opts.data) {
      xhr.send(opts.data);
    } else if (params) {
      xhr.send(params);
    } else {
      xhr.send();
    }
  });
}

/**
 * @param {string} uri
 * @param {HttpProgress} httpProgress
 * @return {Promise<Blob>} Promise object of XHR
 */
export async function xhrGetAsBlob(uri, httpProgress) {
  let opts = new XhrOpts();
  opts.httpProgress = httpProgress;
  opts.responseType = "blob";
  const res = await xhrAsync(uri, opts);
  return res.response;
}

/**
 * @param {string} url
 * @param {string} accessToken
 * @param {Object} params
 * @returns {Promise<Object>}
 */
export async function xhrGetJSONAuth(url, accessToken, params = {}) {
  /** @type {import("./http").XhrOpts} */
  let opts = new XhrOpts();
  opts.params = params;
  opts.responseType = "json";
  opts.headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  let xhr = await xhrAsync(url, opts);
  throwIfXhrFailed(xhr);
  return xhr.response;
}

/*
 * @param {string} url
 * @param {string} accessToken
 * @param {Object} params
 * @returns {Promise<Object>}
 */
export async function xhrDeleteAuth(url, accessToken, params = {}) {
  /** @type {import("./http").XhrOpts} */
  let opts = new XhrOpts();
  opts.method = "DELETE";
  opts.params = params;
  opts.responseType = "json";
  opts.headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  let xhr = await xhrAsync(url, opts);
  throwIfXhrFailed(xhr);
  return xhr.response;
}

/**
 * @param {string} uri
 * @param {HttpProgress} httpProgress
 * @return {Promise<any>} Promise object of XHR
 */
export async function xhrPost(uri, httpProgress) {
  let opts = new XhrOpts();
  opts.method = "POST";
  opts.httpProgress = httpProgress;
  const xhr = await xhrAsync(uri, opts);
  return xhr.response;
}

/**
 * @param {string} uri
 * @param {HttpProgress} httpProgress
 * @return {Promise<Object>} Promise object of JSON
 */
export async function xhrPostAsJSON(uri, params, httpProgress) {
  let opts = new XhrOpts();
  opts.method = "POST";
  let fd = new FormData();
  for (let [k, v] of Object.entries(params)) {
    fd.set(k, v);
  }

  opts.params = params;
  opts.responseType = "json";
  opts.headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  opts.httpProgress = httpProgress;
  const xhr = await xhrAsync(uri, opts);
  return xhr.response;
}

/**
 * @param {string} uri
 * @param {Blob} body
 * @param {HttpProgress} httpProgress
 * @param {string} accessToken
 * @return {Promise<XMLHttpRequest>} Promise object of XHR
 */
export async function xhrPut(uri, body, httpProgress, accessToken = null) {
  let opts = new XhrOpts();
  opts.method = "PUT";
  opts.data = body;
  opts.httpProgress = httpProgress;
  if (accessToken) {
    opts.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }
  const res = await xhrAsync(uri, opts);
  return res;
}

/**
 * @param {XMLHttpRequest} xhr
 */
export async function throwIfXhrFailed(xhr) {
  if (xhr.status >= 200 && xhr.status < 300) {
    return;
  }
  //let s = await rsp.text();
  //let s = await rsp.json();
  //console.log("s:", s);
  const msg = `${xhr.responseURL} failed with ${xhr.status} ${xhr.statusText}.`;
  throw new Error(msg);
}

/**
 * @param {string} url
 * @param  {...string} searchParams
 * @returns {string}
 */
export function mkURL(url, ...searchParams) {
  let n = len(searchParams);
  if (n == 0) {
    return url;
  }
  throwIf(n % 2 != 0, "must have even number of args");
  n = n / 2;
  url = url + "?";
  for (let i = 0; i < n; i++) {
    if (i > 0) {
      url += "&";
    }
    let idx = i * 2;
    let k = searchParams[idx];
    let v = searchParams[idx + 1];
    url = url + `${k}=${enc(v)}`;
  }
  return url;
}

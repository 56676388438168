<script>
  import DialogBase from "./DialogBase.svelte";

  import { focus } from "./actions/focus";
  import { showMessage } from "./message";
  import { telegramNotify, throwIfFetchFailed } from "./http";
  import { testS3Connection, makeFileSystemS3 } from "./fs-s3";
  import { createS3Config } from "./fs";
  
  /** @type {{
    open?: boolean,
    mountfs: (fs: any) => void
  }} */
  let { open = $bindable(false), mountfs } = $props();

  let endpointPlaceholder = "s3.amazonaws.com";

  let initConfig = createS3Config();
  let config = $state(initConfig);
  let testingConnection = $state(false);
  let errorMessage = $state("");

  // name is optional
  let canConnect = $derived(config.secret && config.access && config.bucket && !testingConnection);

  async function onConnect() {
    console.log("onConnect()");
    if (config.endpoint == "") {
      config.endpoint = endpointPlaceholder;
    }
    testingConnection = true;
    errorMessage = "";
    let removeMsg = showMessage("testing s3 connection");
    try {
      let rsp = await testS3Connection(config);
      console.log("rsp", rsp);
      await throwIfFetchFailed(rsp);
      removeMsg();
      let fs = makeFileSystemS3(config);
      const msg = `New S3 connection ${config.bucket}.${config.endpoint}`;
      telegramNotify(msg);

      mountfs(fs);
    } catch (e) {
      console.error(e);
      errorMessage = e.toString();
      removeMsg();
    }
    testingConnection = false;
  }

  function close() {
    open = false;
  }
</script>

<DialogBase bind:open title="Connect S3">
  {#snippet main()}
    <div  class="flex flex-col text-sm">
      <label for="input-name"
        >Name: <span class="text-gray-400">(optional)</span></label
      >
      <input
        use:focus
        type="text"
        id="input-name"
        autocomplete="off"
        class="outline-none border px-2 py-1"
        bind:value={config.name}
      />

      <label class="mt-3" for="input-key">S3 Access Key:</label>
      <input
        type="text"
        id="input-key"
        autocomplete="off"
        class="outline-none border px-2 py-1"
        bind:value={config.access}
      />

      <label class="mt-3" for="input-secret">S3 Secret:</label>
      <input
        type="text"
        id="input-secret"
        autocomplete="off"
        class="outline-none border px-2 py-1"
        bind:value={config.secret}
      />

      <label class="mt-3" for="input-bucket">S3 Bucket:</label>
      <input
        type="text"
        id="input-bucket"
        autocomplete="off"
        class="outline-none border px-2 py-1"
        bind:value={config.bucket}
      />

      <label class="mt-3" for="input-endpoint">Endpoint:</label>
      <input
        type="text"
        id="input-endpoint"
        autocomplete="off"
        placeholder={endpointPlaceholder}
        class="outline-none border px-2 py-1 text-sm"
        bind:value={config.endpoint}
      />

      <div class="mt-4 text-sm">
        <a
          href="https://doc.clickup.com/36014502/d/12b2d6-107/filerionmanual/12b2d6-387/s-3-support"
          class="text-blue-600 underline"
          target="_blank"
          rel="noreferrer">configuration help</a
        >
      </div>

      {#if testingConnection}
        <div class="text-sm text-gray-500 mt-2 mb-2">testing s3 settings...</div>
      {/if}

      {#if errorMessage}
        <div class="mt-2 mb-2 text-red-500 font-medium break-all w-72">
          {errorMessage}
        </div>
      {/if}
    </div>
  {/snippet}

  {#snippet bottom()}
    <div  class="flex flex-row justify-between">
      <button
        onclick={close}
        class="dlg-btn">Cancel</button
      >
      <button
        disabled={!canConnect}
        onclick={onConnect}
        class="dlg-btn"
        >Connect S3</button
      >
    </div>
  {/snippet}
</DialogBase>

<script>
  /** @typedef {import("./fs").BackBlazeConfig} BackBlazeConfig */
  import DialogBase from "./DialogBase.svelte";

  import { focus } from "./actions/focus";

  import {
    mkURL,
    telegramNotify,
    throwIfXhrFailed,
    xhrAsync,
    XhrOpts,
  } from "./http";
  import { makeFileSystemBackBlaze } from "./fs-backblaze";
  import { createBackBlazeConfig } from "./fs";

  
  /** @type {{open?: boolean, mountfs: (fs) => void}} */
  let { open = $bindable(false), mountfs } = $props();

  let config = $state(createBackBlazeConfig());
  let testingConnection = false;
  let errorMessage = "";

  function calcConnectDisabled(appKeyId, appKey, bucket) {
    return !(appKeyId && appKey && bucket);
  }

  function calcAutoConnectDisabled(appKeyId, appKey) {
    return !(appKeyId && appKey);
  }

  /*
  async function listBuckets() {
    let url = `https://api.backblazeb2.com/b2api/v2/b2_authorize_account`;
    let opts = new XhrOpts();
    let key = config.appKeyId + ":" + config.appKey;
    let auth = `Basic ${btoa(key)}`;
    opts.headers["Authorization"] = auth;
    opts.responseType = "json";
    let xhr = await xhrAsync(url, opts);
    throwIfXhrFailed(xhr);
    let o = xhr.response;
    console.log("listBuckets:", o);
  }
*/

  async function autoDetectBucket() {
    let url = mkURL(
      `/api/bb/auth`,
      "appKeyId",
      config.appKeyId,
      "appKey",
      config.appKey
    );
    let opts = new XhrOpts();
    opts.responseType = "json";
    let xhr = await xhrAsync(url, opts);
    throwIfXhrFailed(xhr);
    let o = xhr.response;
    console.log("autoDetectBucket:", o);

    config.bucketId = o.allowed.bucketId;
    config.bucketName = o.allowed.bucketName;

    let fs = makeFileSystemBackBlaze(config);
    const msg = `New BackBlaze connection ${config.bucketName}`;
    telegramNotify(msg);

    mountfs(fs);
  }

  async function onConnect() {
    console.log("onConnect()");
  }

  function close() {
    open = false;
  }
  let connectDisabled = $derived(calcConnectDisabled(
    config.appKeyId,
    config.appKey,
    config.bucketName
  ));
  let autoDetectDisabled = $derived(calcAutoConnectDisabled(
    config.appKeyId,
    config.appKey
  ));
</script>

<DialogBase bind:open title="Connect BackBlaze">
  {#snippet main()}
    <div  class="flex flex-col w-[54ch] text-sm">
      <label class="mt-1" for="input-key">Application Key ID:</label>
      <input
        use:focus
        type="text"
        id="input-key"
        autocomplete="off"
        class="mt-1 px-2 py-1 outline-none border text-sm"
        bind:value={config.appKeyId}
      />

      <label class="mt-2" for="input-secret">Application Key:</label>
      <input
        type="text"
        id="input-secret"
        autocomplete="off"
        class="mt-1 px-2 py-1 outline-none border text-sm"
        bind:value={config.appKey}
      />

      <label class="mt-2" for="input-secret">Bucket:</label>
      <div class="mt-1 flex flex-row">
        <input
          type="text"
          id="input-secret"
          autocomplete="off"
          class="px-2 py-1 flex-grow outline-none border text-sm"
          bind:value={config.bucketName}
        />
        <button
          onclick={autoDetectBucket}
          disabled={autoDetectDisabled}
          class="ml-4 dlg-btn"
          >Auto-Detect
        </button>
      </div>

      <div class="mt-2 text-sm">
        <a
          href="https://doc.clickup.com/36014502/d/12b2d6-107/filerionmanual/12b2d6-387/s-3-support"
          class="text-blue-600 underline"
          target="_blank"
          rel="noreferrer">configuration help</a
        >
      </div>

      {#if testingConnection}
        <div class="text-sm text-gray-500 mt-2 mb-2">
          testing BackBlaze settings...
        </div>
      {/if}

      {#if errorMessage}
        <div class="mt-2 mb-2 text-red-500 font-medium break-all w-72">
          {errorMessage}
        </div>
      {/if}
    </div>
  {/snippet}

  {#snippet bottom()}
    <div  class="flex flex-row justify-between">
      <button
        onclick={close}
        class="dlg-btn">Cancel</button
      >
      <button
        onclick={onConnect}
        disabled={connectDisabled}
        class="dlg-btn"
        >Connect BackBlaze
      </button>
    </div>
  {/snippet}
</DialogBase>

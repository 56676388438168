<script>
  /** @typedef {import("./bookmarks").Bookmark} Bookmark */
  import clickoutside from "./actions/clickoutside";
  import { bookmarkName } from "./bookmarks";
  import { bookmarkExists, removeBookmark } from "./state.svelte";
  import { len } from "./util";
  import { bookmarks } from "./state.svelte";

  /**
    @type {{
      currBookmark: Bookmark,
      dismiss: () => void,
      addtobookmarks: () => void,
      gotobookmark: (b) => void
    }}
  */
  let { currBookmark, dismiss, addtobookmarks, gotobookmark } = $props();

  let inBookmarks = $derived(bookmarkExists(currBookmark));

  function addToBookmarks() {
    addtobookmarks();
    dismiss();
  }

  /**
   * @param {import("./bookmarks").Bookmark} b
   */
  function goToBookmark(b) {
    gotobookmark(b);
    dismiss();
  }

  function removeCurrentBookmark() {
    removeBookmark(currBookmark);
    dismiss();
  }

  function editBookmars() {
    console.log("editBookmarks");
  }
</script>

<div
  onclickoutside={dismiss}
  use:clickoutside
  class="absolute flex flex-col bg-white px-2 py-2 border border-gray-300 shadow-md cursor-pointer top-[100%] left text-sm text-black max-h-[85vh] max-w-[42vw] overflow-auto z-20"
>
  {#if inBookmarks}
    <button
      class="flex hover:bg-gray-200 truncate px-2 py-0.5 min-h-[1.5rem] text-red-500"
      onclick={removeCurrentBookmark}
    >
      Remove this folder from bookmarks
    </button>
  {:else}
    <button
      class="flex hover:bg-gray-200 truncate px-2 py-0.5 min-h-[1.5rem]"
      onclick={addToBookmarks}
    >
      Bookmark this folder
    </button>
  {/if}

  <!-- <button
    class="flex hover:bg-gray-200 truncate px-2 py-0.5"
    onclick={editBookmars}
  >
    Edit bookmarks
  </button> -->
  {#if len(bookmarks) > 0}
    <hr class="mt-2 mb-2 py-0.5" />
  {/if}
  {#each bookmarks as b}
    <button
      class="flex hover:bg-gray-200 truncate px-2 py-0.5 min-h-[1.5rem]"
      onclick={() => goToBookmark(b)}
    >
      {bookmarkName(b)}
    </button>
  {/each}
</div>

import { getStoredBookmarks } from "./state.svelte";
import { len } from "./util";

export let corruptOneDrive = false;
export let corruptGoogleDrive = false;

/**
 * @param {boolean} v
 */
export function setCorruptOneDrive(v) {
  corruptOneDrive = v;
}

export function setCorruptGoogleDrive(v) {
  corruptGoogleDrive = v;
}

export function installDev() {
  let dev = {};
  dev.corruptOneDrive = () => {
    corruptOneDrive = true;
    console.log("corruptOneDrive: scheduled corrupt token");
  };
  dev.corruptGoogleDrive = () => {
    corruptGoogleDrive = true;
    console.log("corruptGoogleDrive: scheduled corrupt token");
  };

  dev.dumpStorageConfigs = () => {
    let a = getStoredBookmarks();
    for (let o of a) {
      console.log(o);
    }
  };

  dev.dumpLocalStorage = () => {
    let i = 0;
    while (true) {
      let key = localStorage.key(i);
      if (key == null) {
        return;
      }
      let v = localStorage.getItem(key);
      if (len(v) < 64) {
        console.log(`${key}: ${v}`);
      } else {
        console.log(`${key}:`);
        console.log(JSON.parse(v));
      }
      i++;
    }
  };

  // @ts-ignore
  window.dev = dev;
  console.log("installDev()");
}

/** @typedef {import("./fs").FSConfig} FSConfig */
/** @typedef {import("./fs").FS} FS */
/** @typedef {import("./bookmarks").Bookmark} Bookmark */

import { bookmarkFromFSState } from "./bookmarks";
import {
  fsConfigEq,
  FSDirState,
  fsTypeBackBlaze,
  fsTypeDropbox,
  fsTypeFromFSConfig,
  fsTypeFromID,
  fsTypeLocal,
  fsTypeOneDrive,
  fsTypeS3,
  fsTypeTestFiles,
} from "./fs";
import { len } from "./util";

let lsKeyFSConfigs = "fm-fsconfigs";

/**
 * @returns {FSConfig[]}
 */
function readInitialFSConfigs() {
  let v = localStorage.getItem(lsKeyFSConfigs) || "[]";
  /** @type {FSConfig[]} */
  let res = JSON.parse(v);
  console.log("initialFSConfigs:", res);
  return res;
}

// keeps a list of mounted file system so that we don't
// re-read directories on every mount
/** @type {FS[]} */
export let mountedFileSystems = $state([]);

/** @type {FSConfig[]} */
export let savedFsConfigs = $state(readInitialFSConfigs());

/**
 * @param {FSConfig} c
 */
function rememberFsConfig(c) {
  let fsType = fsTypeFromFSConfig(c);
  switch (fsType) {
    case fsTypeS3:
    case fsTypeBackBlaze:
      break;
    default:
      return;
    // we don't remember these:
    // fsTypeTestFiles : obvious reasons
    // fsTypeDropbox : prefer to re-auth for each session
    // fsTypeOneDrive : prefer to re-auth for each session
    // fsTypeLocal : can't store dirHandle in local storage
    //    TODO: store dirHandle in IndexedDB
  }
  for (let c2 of savedFsConfigs) {
    if (fsConfigEq(c, c2)) {
      return;
    }
  }
  console.log("rememberFsConfig:", c);
  savedFsConfigs.push(c);
  let v = JSON.stringify(savedFsConfigs);
  console.log("saving fsConfigs:", v);
  localStorage.setItem(lsKeyFSConfigs, v);
}

/**
 * @param {FS} fs
 */
export function rememberFileSystem(fs) {
  rememberFsConfig(fs.config);
  // don't remember multipe connections for the same fs
  let conf = fs.config;
  for (let existingFS of mountedFileSystems) {
    let conf2 = existingFS.config;
    if (fsConfigEq(conf, conf2)) {
      console.log(
        "rememberFileSystem: already fs with config",
        fs.config,
        existingFS.config
      );
      return;
    }
  }
  console.log("rememberFileSystem: new fs", fs.config);
  mountedFileSystems.push(fs);
}

const lsKeyBookmarks = "fm-bookmarks";

/**
 * @returns {Bookmark[]}
 */
function initialBookmarks() {
  let sv = localStorage.getItem(lsKeyBookmarks) || "[]";
  /** @type {Bookmark[]} */
  let res = JSON.parse(sv);
  console.log("initialBookmarks:", res);
  return res;
}

/** @returns {Bookmark[]} */
export function getStoredBookmarks() {
  return initialBookmarks();
}

/** @type {Bookmark[]} */
export let bookmarks = $state(initialBookmarks());

/**
 * @param {Bookmark} b
 * @returns {boolean}
 */
function shouldStoreBookmark(b) {
  let fsType = fsTypeFromID(b.fsID);
  switch (fsType) {
    case fsTypeTestFiles:
    case fsTypeOneDrive:
    case fsTypeDropbox:
    case fsTypeLocal:
      return false;
  }

  return true;
}

// $effect.root() needed for $effect() outside of a component
$effect.root(() => {
  $effect(() => {
    let stored = [];
    for (let b of bookmarks) {
      if (shouldStoreBookmark(b)) {
        stored.push(b);
      }
    }
    let v = JSON.stringify(stored);
    console.log("saving bookmarks:", stored);
    localStorage.setItem(lsKeyBookmarks, v);
  });
  return () => {
    console.log("cleanup of root effect for saving bookmarks");
  };
});

/**
 * @param {Bookmark} b1
 * @param {Bookmark} b2
 * @returns boolean
 */
export function bookmarkEq(b1, b2) {
  if (b1.fsID != b2.fsID) {
    return false;
  }
  if (b1.dir != b2.dir) {
    return false;
  }
  return true;
}

/**
 * @param {Bookmark} b
 */
export function removeBookmark(b) {
  console.log("removeBookmark:", b);
  function removeBookmarkFromArray(a, b) {
    let n = len(a);
    for (let i = 0; i < n; i++) {
      let b2 = a[i];
      if (bookmarkEq(b, b2)) {
        a.splice(i, 1);
        return;
      }
    }
    console.log("didn't remove bookmark:", b);
  }
  removeBookmarkFromArray(bookmarks, b);
}

/**
 * @param {Bookmark} b
 * @returns {boolean}
 */
export function bookmarkExists(b) {
  for (let b2 of bookmarks) {
    if (bookmarkEq(b, b2)) {
      return true;
    }
  }
  return false;
}

/**
 * @param {Bookmark} b
 * @param {string} dir
 */
export function addBookmark(b, dir) {
  b.dir = dir;
  if (bookmarkExists(b)) {
    return;
  }
  bookmarks.push(b);
}

/**
 * @param {FSDirState} fsState
 */
export function addBookmarkFSState(fsState) {
  console.log("addBookmarkFSState:", fsState);
  const b = bookmarkFromFSState(fsState);
  addBookmark(b, fsState.currDir);
}

<script>
  import clickoutside from "./actions/clickoutside";
  import MenuItems from "./MenuItems.svelte";

  import { ensurevisible } from "./actions/ensurevisible";
  import { getContext } from "svelte";
  import { throwIf } from "./util";

  let dismissContextMenu = getContext("fnDismissContextMenu");
  throwIf(!dismissContextMenu, "needs dismissContextMenu");

  /** @type {{
    menu?: any,
    pageX?: number,
    pageY?: number
  }} */
  let { menu = [], pageX = 0, pageY = 0 } = $props();
</script>

<button
  class="absolute block text-black cursor-pointer z-50"
  style="top: {pageY}px; left: {pageX}px"
  onclick={dismissContextMenu}
  use:clickoutside
  use:ensurevisible
  onclickoutside={dismissContextMenu}
>
  <MenuItems {menu} />
</button>
